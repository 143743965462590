html {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	background-color: #303030;
}

.App {
	text-align: center;
	width: 100%;
	height: 100vh;
	background-color: #303030;
}

#root body .App {
	min-height: 100vh;
	background-color: #303030;
}

.MuiDrawer-paperAnchorDockedLeft {
	border-right: none !important;
}

.MuiInput-underline.Mui-disabled:before {
	border-bottom-style: solid !important;
}

form {
	width: 100%;
}

.MuiGrid-spacing-xs-1 > .MuiGrid-item {
	padding: 0px 4px !important;
}

.MuiFormControl-marginDense {
	margin-top: 4px !important;
}

.MuiContainer-root {
	margin-top: 80px !important;
}

.MuiListItemIcon-root {
	min-width: 32px !important;
	margin-right: 16px;
}
